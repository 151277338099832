.radix-themes {
  --default-font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI (Custom)', Roboto, 'Helvetica Neue',
    'Open Sans (Custom)', system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';

  --cursor-button: pointer;
  --cursor-checkbox: pointer;
  --cursor-disabled: not-allowed;
  --cursor-link: pointer;
  --cursor-menu-item: pointer;
  --cursor-radio: pointer;
  --cursor-slider-thumb: pointer;
  --cursor-slider-thumb-active: pointer;
  --cursor-switch: pointer;
}

html {
  cursor: default;
  line-height: 1.5;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  word-break: break-word;
  font-variant-numeric: tabular-nums;
}

html,
body {
  font-family: 'Helvetica', sans-serif;
  height: 100%;
}

#root,
#__next {
  height: 100%;
  isolation: isolate;
}
