@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/HELVETICA_BOLD.OTF') format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  font-style: oblique;
  font-weight: 900;
  src: url('/fonts/HELVETICA_BOLDOBLIQUE.OTF') format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/HELVETICA_CYR_BOLDOBLIQUE.TTF') format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/HELVETICA_REGULAR.TTF') format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/HELVETICA_LIGHT.OTF') format('truetype');
}

@font-face {
  font-family: 'Helvetica';
  font-style: oblique;
  font-weight: 400;
  src: url('/fonts/HELVETICA_CYR_OBLIQUE.TTF') format('truetype');
}
